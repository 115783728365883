import React, {useEffect, useState} from 'react'

import BeanSlide from './slides/BeanSlide';
import FullSlide from './slides/FullSlide';
import LargeImageSlide from './slides/LargeImageSlide';
import CrossSellBean from './slides/CrossSellBean';

const CarouselSlide = ({
    slide,
    state: {
        currentView,
        slidesPerView,
        classes
    },
    slideIndex,
    selectSlides,
}) => {
    const [visible, setVisible] = useState(false);

    const attributes = {};
    if( selectSlides ) attributes.dangerouslySetInnerHTML = {__html: slide.outerHTML};

    const components = {
        bean: BeanSlide,
        hightlight: FullSlide,
        large_image_slide: LargeImageSlide,
        square_bean_slide: CrossSellBean
    };

    useEffect(
      () => {
        if (slidesPerView === 1) {
          // mobile
          currentView === slideIndex ? setVisible(true) : setVisible(false);
        } else {
          // desktop
          currentView === 0 && slideIndex <= 2
            ? setVisible(true)
            : setVisible(false);

          if (currentView > 0 && slideIndex > 2) {
            setVisible(true);
          }
        }
      },
      [currentView, slidesPerView]
    );

    const makeClasses = () => `${classes.slide} slide-${slideIndex}${visible ? ' ' + classes.slideActive : ''}`;

    const renderSlide = () => {
        const SlideComponent = components[slide.type]
        return <SlideComponent slide={slide} visible={visible} />
    };

    return (
        <li
            className={makeClasses()}
            aria-hidden={!visible}
            {...attributes}>
            {selectSlides ? null : renderSlide()}
        </li>
    );
}


export default CarouselSlide
