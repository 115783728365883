import uuid from 'uuid/v4';
import localStorageClient from './localStorageClient'

const key = process.env.CRT_LOCAL_CART_KEY
const orderKey = process.env.CRT_LOCAL_ORDER_ID_KEY
const eventType = 'localCart:updated'
const timedType = 'eticket'

const subscribe = (listener = () => null) => document.addEventListener(eventType, listener)

const unSubscribe = (listener = () => null) => document.removeEventListener(eventType, listener)

const has = (callback = () => false) => doGet().reduce(
  (acc, item) => !acc && callback(item) ? true : acc,
  false
)

const doGet = () => localStorageClient.getItem(key) || []

const get = (callback = () => true) => doGet().filter(callback)

const getItem = (callback = () => false) => doGet().reduce(
  (acc, item) => !acc && callback(item) ? item : acc,
  undefined
)

const getTime = () => doGet().reduce(
  (acc, { itemType, creationTime }) => (
    itemType === timedType
    && (
      !acc ||
      creationTime < acc
    )
  ) ? creationTime : acc,
  undefined
)

const doSet = cart => localStorageClient.setItem(key, cart)

const dispatch = () => document.dispatchEvent(new CustomEvent(eventType))

const clearOrderId = () => localStorageClient.removeItem(orderKey)

const set = cart => {
  if (cart === undefined) {
    throw new Error('localCartClient::set - [cart] is required')
  } else {
    doSet(cart)
    clearOrderId()
    dispatch()
  }
}

const addItems = items => {
  if (items === undefined) {
    throw new Error('localCartClient::addItem - [items] is required')
  } else {
    doSet([
      ...doGet(),
      ...items
    ])
    clearOrderId()
    dispatch()
  }
}

const replaceItem = (callback = () => false, replacementItem) => {
  if (replacementItem === undefined) {
    throw new Error('localCartClient::replaceItem - [replacementItem] is required')
  } else {
    doSet(
      doGet().reduce(
        (acc, item) => callback(item) ? [...acc, replacementItem] : [...acc, item],
        []
      )
    )
    clearOrderId()
    dispatch()
  }
}

const removeItems = (callback = () => false) => {
  doSet(
    doGet().filter(
      item => !callback(item)
    )
  )
  clearOrderId()
  dispatch()
}

const clear = () => {

  doSet([])
  clearOrderId()
  dispatch()
}

/**
 * Returns the cart items from storage.
 */
export const getCartItemsFromStorage = () => {
  const CRT_LOCAL_CART_KEY = process.env.CRT_LOCAL_CART_KEY;

  return localStorageClient.getItem(CRT_LOCAL_CART_KEY) || [];
}

/**
 * Set the cart items to storage.
 */
export const setCartItemsToStorage = () => {
  const CRT_LOCAL_CART_KEY = process.env.CRT_LOCAL_CART_KEY;

  return localStorageClient.getItem(CRT_LOCAL_CART_KEY) || [];
}

/**
 * Merge cart items from several source grouping items by their itemId.
 * Example: items from the current engine cart and items from storage.
 */
export const mergeCartItems = (items1, items2) => {
  return [...items1, ...items2].reduce((acc, currentItem) => {
    const inAccItem = acc.find(item => item.itemId === currentItem.itemId);

    if (inAccItem) {
      inAccItem.quantity += currentItem.quantity;
    } else {
      return [
        ...acc,
        currentItem,
      ];
    }

    return acc;

  }, []);
}

/**
 * Add an item to cart items array, grouping them by variant code.
 */
export const addCartItemToItems = (items, quantity, variant, model, updateUrl) => {
  const isNew = !items.find(item => item.variant.code === variant.code);

  if (isNew) {
    const item = createItem(quantity, variant, model, updateUrl)

    return [
      ...items,
      item,
    ]
  } else {
    return item.map(item => {
      if (item.variant.code === variant.code) {
        return {
          ...item,
          quantity: item.quantity + 1,
        };
      }

      return item;
    });
  }
};

export const createItemId = () => uuid();

export const createGroupId = () => uuid();

/**
 * Instantiates a cart item from a product
 * 
 * @param {number} quantity
 * @param {object} variant
 * @param {object} model
 * @param {string|undefined} groupId
 * @param {string|undefined} updateUrl
 * @param {object} productTypeLabel
 * @param {array} freeProducts
 */
export const createItem = ({ quantity, variant, model, groupId, updateUrl, productTypeLabel, freeProducts }) => {
  const itemId = createItemId();

  const item = {
    itemId,
    itemType: 'cc',
    groupId,
    quantity: variant.quantity ? variant.quantity + quantity : quantity,
    variant: {
      ...variant,
      price: parseFloat(variant.price),
    },
    model: {
      ...model,
      updateUrl,
    },
    productTypeLabel,
    freeProducts,
  };

  return item;
}

export default {
  subscribe,
  unSubscribe,
  has,
  get,
  getItem,
  getTime,
  set,
  addItems,
  replaceItem,
  createItem,
  removeItems,
  clear,
  timedType,
  clearOrderId
}
