import React from 'react'
import ReactDOM from 'react-dom'

import Carousel from './components/Carousel'

import Cart from '../common/helpers/localCartClient'

const carousels = document.querySelectorAll('[data-carousel]')

const exclude = (children, excludeCartItems = false) => {
  if (!excludeCartItems) {

    return children
  }

  const items = Cart.get()

  return Array.from(children).filter(
    child => {

      return items.find(item => item.model.code === child.getAttribute('data-code')) ? false : true
    }
  )
}

Array.prototype.forEach.call(carousels, (carousel) => {
  const carouselOpts = {...window[carousel.dataset.carousel], id: carousel.dataset.carousel};
  carouselOpts.slides = carouselOpts.selectSlides
    ? exclude(document.querySelector(`[data-carousel-slides="${carouselOpts.id}"]`).children, carouselOpts.excludeCartItems)
    : carouselOpts.slides

  ReactDOM.render(<Carousel options={carouselOpts}/>, carousel)
})
