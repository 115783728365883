export const carouselReducer = (state, action) => {
    switch (action.type) {
        case 'NEXT_VIEW':
            if(state.currentView < (state.nbViews - 1)) {
                return {
                    ...state,
                    currentView: state.currentView + 1
                }
            }
            return state

        case 'PREV_VIEW':
            if(state.currentView > 0) {
                return {
                    ...state,
                    currentView: state.currentView - 1
                }
            }
            return state

        case 'GO_TO_VIEW':
            return {
                ...state,
                currentView: action.payload
            }

        case 'MAKE_OPTIONS':
            return {
                ...state,
                ...action.payload
            }

        default:
            return state;
    }
};
