const getRegex = regex => Object.keys(localStorage).reduce(
  (acc, key) => (
    acc
    || (
      key.match(new RegExp(regex))
      && localStorage.getItem(key)
    )
  ),
  false
) || undefined

const getUserDataItem = (key, userData) => {
  const item = userData.UserAttributes.find(({ Name }) => Name === key);
  if (item) {
    return item.Value;
  }

  return undefined;
};

const getItem = (key) => JSON.parse(localStorage.getItem(key));

const setItem = (key, value) => localStorage.setItem(key, JSON.stringify(value))

const removeItem = key => localStorage.removeItem(key)

export default {
  getRegex,
  getUserDataItem,
  getItem,
  setItem,
  removeItem,
};
