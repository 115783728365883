import React from 'react'

const CLOUDINARY_TIMESTAMP = process.env.CLOUDINARY_TIMESTAMP;

const CrossSellBean = ({
    slide: {
        data: {
            url,
            priceLabel,
            priceValue,
            dateLabel,
            title,
            subtitle,
            place,
            image,
            btnLabel
        }
    },
    visible,
}) => {

    return (
        <a href={ url } className="crtCrossSellBean" tabIndex={visible ? 0 : -1}>
            {dateLabel &&
                    <div className="crtBean-date">{ dateLabel }</div>
            }
            <div className="crtCrossSellBean-content">
                <img
                    src={`https://res.cloudinary.com/du5jifpgg/image/${ image.type }/e_blur:1000,t_cross_sell_bean_w330_h285,q_1,f_auto/v${ CLOUDINARY_TIMESTAMP }/${ image.url }`}
                    data-srcset={`https://res.cloudinary.com/du5jifpgg/image/${ image.type }/t_cross_sell_bean_w330_h285,q_auto,f_auto/v${ CLOUDINARY_TIMESTAMP }/${ image.url } 330w,
                            https://res.cloudinary.com/du5jifpgg/image/${ image.type }/t_cross_sell_bean_w660_h570,q_auto,f_auto/v${ CLOUDINARY_TIMESTAMP }/${ image.url } 660w`}
                    sizes="
                        (min-width: 64rem) 26.25rem,
                        (max-width: 64rem) 80vw"
                    alt={ image.legend }
                    className="lazyload tns-lazy-img" />
                <div className="crtCrossSellBean-infos">
                    <div className="crtCrossSellBean-title">{ title }</div>
                    { place &&
                        <div className="crtCrossSellBean-place">{ place }</div>
                    }
                    { subtitle &&
                        <div className="crtCrossSellBean-subtitle">{ subtitle }</div>
                    }
                    { (priceValue || priceLabel) &&
                        <div className="crtCrossSellBean-price">
                            {priceLabel &&
                                <span className="crtCrossSellBean-priceLabel">{ priceLabel }</span>
                            }
                            <span className="crtCrossSellBean-priceValue">{ priceValue }</span>
                        </div>
                    }
                </div>
            </div>
            { btnLabel &&
                <button
                    tabIndex={visible ? 0 : -1}
                    className="crtBtn">
                    {window.carouselData.translations[btnLabel]}
                </button>
            }
        </a>
    );
}


export default CrossSellBean
