import React from 'react'

const BeanSlide = ({
    data: {
        typeidentifier,
        url,
        priceLabel,
        priceValue,
        dateLabel,
        image,
        title,
        subtitle,
        place,
        btnLabel
    }
}) => {

    return (
        <a href={ url } className="crtBean crtBeanProduct">
            {(typeidentifier === 'FETE_ET_MANIFESTATION'
                || typeidentifier === 'FranceBilletEventOverride')
                && dateLabel &&
                    <div className="crtBean-date">{ dateLabel }</div>
            }
            { (priceValue || priceLabel) &&
                <div className="crtBean-price">
                    {priceLabel &&
                        <span className="crtBean-price-label">{ priceLabel }</span>
                    }
                    <span className="crtBean-price-value">{ priceValue }</span>
                </div>
            }
            <div className="crtBean-img">
                <img
                    src={`https://res.cloudinary.com/du5jifpgg/image/${ image.type }/e_blur:1000,t_cross_sell_bean_w330_h285,q_1,f_auto/v${ image.timestamp }/${ image.url }`}
                    data-srcset={`https://res.cloudinary.com/du5jifpgg/image/${ image.type }/t_cross_sell_bean_w330_h285,q_auto,f_auto/v${ image.timestamp }/${ image.url } 300w,
                            https://res.cloudinary.com/du5jifpgg/image/${ image.type }/t_cross_sell_bean_w660_h570,q_auto,f_auto/v${ image.timestamp }/${ image.url } 600w`}
                    sizes="
                        (min-width: 64rem) 26.25rem,
                        (max-width: 64rem) 100vw"
                    alt={ image.legend }
                    className="lazyload tns-lazy-img" />
                <div className="crtBean-infos">
                    <div className="crtBean-title">{ title }</div>
                    { place &&
                        <div className="crtBean-place">{ place }</div>
                    }
                    { subtitle &&
                        <div className="crtBean-subtitle">{ subtitle }</div>
                    }
                </div>
            </div>
            <button className="crtBtn">{ btnLabel }</button>
        </a>
    );
}


export default BeanSlide
