import React from 'react'

export const PrevBtn = ({state, dispatch, children}) => {

    const handleClick = e => {
        dispatch({type: 'PREV_VIEW'})
    }

    return (
        <button type="button" className={state.classes.prevBtn} onClick={handleClick}>
            <svg viewBox="0 0 100 100" className="crt-icon-fb">
                <use xlinkHref="#crt-arrow-left"></use>
            </svg>
            <span className="sr-only">{children}</span>
        </button>
    )

};

export const NextBtn = ({state, dispatch, children}) => {

    const handleClick = () => {
      dispatch({type: 'NEXT_VIEW'})
  }

    return (
        <button type="button" className={state.classes.nextBtn} onClick={handleClick}>
            <svg viewBox="0 0 100 100" className="crt-icon-fb">
                <use xlinkHref="#crt-arrow-right"></use>
            </svg>
            <span className="sr-only">{children}</span>
        </button>
    )
}
