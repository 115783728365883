import React, {useReducer, useLayoutEffect, useState, useEffect, useRef} from 'react'
import debounce from 'lodash/debounce'

import {carouselReducer} from '../store/reducer'

import CarouselSlide from './CarouselSlide'
import CarouselList from './CarouselList'
import CarouselDots from './CarouselDots'
import {NextBtn, PrevBtn} from './Buttons'

const CarouselFullWrapper = ({ children }) => (
    // @todo S'il faut ajouter les boutons, il faudra encapsuler avec crtCarousel-inner
    // <div className="crtCarousel-inner">{children}</div>
    children
);

const CarouselButtonsFullWrapper = ({ children }) => (
    <div className="crtCarousel-btns crtCarouselFull-btns">{children}</div>
);

const CarouselButtons = ({ dispatch, state }) => (
    <>
        <PrevBtn dispatch={dispatch} state={state}>Previous Slide</PrevBtn>
        <NextBtn dispatch={dispatch} state={state}>Next Slide</NextBtn>
    </>
);

const FakeSlide = ({ slide, position }) => (
    <li
        className={`crtCarouselFakeSlide is-${position}`}
        aria-hidden={true}
        tabIndex="-1"
        dangerouslySetInnerHTML={slide ? {__html: slide.outerHTML} : undefined}
        >
    </li>
)

const CarouselItems = ({ dispatch, state, options }) => (
  <>
    <CarouselList state={state} dispatch={dispatch}>
      {options.slides.item !== undefined && options.full && (
        <FakeSlide
          position='first'
          slide={options.slides.item(options.slides.length - 1)}
        />
      )}
      {Array.prototype.map.call(options.slides, (slide, i) => (
        <CarouselSlide
          key={i}
          slide={slide}
          state={state}
          slideIndex={i}
          selectSlides={options.selectSlides}
        />
      ))}
      {options.slides.item !== undefined && options.full && (
        <FakeSlide position='last' slide={options.slides.item(0)} />
      )}
    </CarouselList>
    {(state.dotsNav && (state.nbSlides > state.slidesPerView)) &&
      <CarouselDots dispatch={dispatch} state={state} />}
  </>
)

const Carousel = ({ options }) => {
    const [matchQuery, setMatchQuery] = useState('');

    const defaultOptions = {
        currentView: 0,
        transition: 0.3,
        slidesPerView: 3,
        swipe: false,
        gutter: 30,
        buttons: false,
        full: false,
        transitionDuration: 300,
        classes: {
            main: 'CarouselWpr crtCarousel',
            prevBtn: 'crtCarouselBtn',
            nextBtn: 'crtCarouselBtn',
            dotsContainer: 'crtCarousel-dots crtCarouselBean-dots-mobiles',
            dot: 'CarouselDot crtCarousel-dot',
            dotActive: 'is-active',
            listContainer: 'crtCarouselMain crtCarousel-inner',
            list: 'crtCarouselList',
            slide: 'crtCarouselSlide',
            slideActive: 'is-active',
        },
        dotsNav: true,
        dotsText: 'Aller à la vue',
        dotsLabel: 'Choisir la vue à afficher'
    }

    const initialCarouselState = {
        ...defaultOptions,
        ...options,
        classes: {
            ...defaultOptions.classes,
            ...options.classes
        },
        nbSlides: options.slides.length,
        nbViews: Math.ceil(options.slides.length / options.slidesPerView)
    };

    const breakpoints = initialCarouselState.breakpoints ? Object.keys(initialCarouselState.breakpoints) : null

    const [state, dispatch] = useReducer(carouselReducer, initialCarouselState);

    useLayoutEffect(() => {
        function makeOptions() {
            if (breakpoints !== null) {
                let newOptions = initialCarouselState
                breakpoints.map(breakpoint => {
                    if (window.matchMedia(breakpoint).matches && breakpoints !== matchQuery) {
                        setMatchQuery(breakpoint)
                        newOptions = {
                            ...newOptions,
                            ...initialCarouselState.breakpoints[breakpoint],
                        }
                        newOptions.nbViews = Math.ceil(newOptions.nbSlides/newOptions.slidesPerView)
                    }
                })
                if(newOptions.nbSlides === 1) newOptions.swipe = false
                // Store the new options
                dispatch({type:'MAKE_OPTIONS', payload: newOptions})
            }
        }

        const debounceMakeOptions = debounce(makeOptions, 150)

        window.addEventListener('resize', debounceMakeOptions);
        //  First call
        makeOptions();
        //remove listener on destroy
        return () => window.removeEventListener('resize', debounceMakeOptions);
    }, [])

    const previousViewRef = useRef(state.currentView)
    useEffect(() => {
        if (state.currentView !== previousViewRef.current) {
            const tracking = (index, carouselType = 'edito') => {
                if (dataLayer) {
                    dataLayer.push({
                        'event': 'clicSlideShowDot',
                        'sliderType': carouselType ?? 'edito',
                        'slideNo': index,
                    });
                }
            }

            tracking(state.currentView, state.carouselType)
        }

        previousViewRef.current = state.currentView
    }, [state.currentView])

    return (
        <div className={state.classes.main}>
        {
            state.full ? (
                <CarouselFullWrapper>
                    {state.buttons && (
                        <CarouselButtonsFullWrapper>
                            <CarouselButtons dispatch={dispatch} state={state} />
                        </CarouselButtonsFullWrapper>
                    )}
                    <CarouselItems dispatch={dispatch} state={state} options={options}/>
                </CarouselFullWrapper>
            ) : (
                <>
                    {state.buttons && <CarouselButtons dispatch={dispatch} state={state} />}
                    <CarouselItems dispatch={dispatch} state={state} options={options}/>
                </>
            )
        }
        </div>
    )
}

export default Carousel
