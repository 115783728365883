import React, {useRef, useEffect} from 'react'

const aria = {
    TAB: 9,
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40,
};

const Bullet = ({dispatch, index, state}) => {
    const handleClick = () => {
        dispatch({type: 'GO_TO_VIEW', payload: index})
    }

    const isCurrent = () => state.currentView === index

    return (
        <button type="button"
            className={`crtCarousel-dot${isCurrent() ? ' is-active' : ''}`}
            aria-disabled={isCurrent()}
            onClick={handleClick}
            tabIndex={ isCurrent() ? 0 : -1 }>
            <span className="sr-only">{state.dotsText + ' ' + index}</span>
        </button>
    )
};

const CarouselDots = ({state, dispatch}) => {
    const nbBullets = [...Array(Math.ceil(state.nbSlides / state.slidesPerView)).keys()];
    const ref = useRef(null);

    useEffect(() => {
        const keyboard = (e) => {
            e.preventDefault()
            const key = e.which || e.keyCode;
            switch (key) {
                case aria.RIGHT:
                    dispatch({type: "NEXT_VIEW"})
                    break;
                case aria.UP:
                    dispatch({type: "NEXT_VIEW"})
                    break;
                case aria.LEFT:
                    dispatch({type: "PREV_VIEW"})
                    break;
                case aria.DOWN:
                    dispatch({type: "PREV_VIEW"})
                    break;
            }
        }
        ref.current.addEventListener('keyup', keyboard);

        return () => ref.current.removeEventListener('keyup', keyboard)
    }, [ref.current])

    return (
        <ul className={state.classes.dotsContainer}
            aria-label={state.dotsLabel}
            ref={ref}>
            {nbBullets.map((bullet, i) => (
                <Bullet key={i} dispatch={dispatch} index={i} state={state}/>
            ))}
        </ul>
    )

};

export default CarouselDots
