import React, {useRef, useEffect} from 'react'

const CLOUDINARY_TIMESTAMP = process.env.CLOUDINARY_TIMESTAMP;


const SlideImage = ({
    data: {
        image,
}}) => (
    <div className="crtProductImage">
        <picture>
            <source media="(min-width: 1025px)"
                    data-srcset={`https://res.cloudinary.com/du5jifpgg/image/${ image.type }/t_product_slide_w950_h600,q_auto,f_auto/v${ CLOUDINARY_TIMESTAMP }/${ image.url } 1x,
                        https://res.cloudinary.com/du5jifpgg/image/${ image.type }/t_product_slide_w1900_h1200,q_auto,f_auto/v${ CLOUDINARY_TIMESTAMP }/${ image.url } 2x`} />

            <source media="(max-width: 1024px)"
                    data-srcset={`https://res.cloudinary.com/du5jifpgg/image/${ image.type }/t_product_slide_w350_h300,q_auto,f_auto/v${ CLOUDINARY_TIMESTAMP }/${ image.url } 1x,
                        https://res.cloudinary.com/du5jifpgg/image/${ image.type }/t_product_slide_w700_h600,q_auto,f_auto/v${ CLOUDINARY_TIMESTAMP }/${ image.url } 2x`} />

            <img
                data-src={`https://res.cloudinary.com/du5jifpgg/image/${ image.type }/t_product_slide_w350_h300,q_auto,f_auto/v${ CLOUDINARY_TIMESTAMP }/${ image.url }`}
                draggable="false"
                alt={ image.legend }
                className="lazyload tns-lazy-img" />
        </picture>
    </div>
);

const SlideVideo = ({
    data: {
        image,
    },
    visible
}) => {
    const yt = useRef(null);

    useEffect(() => {
      !visible &&
        yt.current.contentWindow.postMessage(
          '{"event":"command","func":"stopVideo","args":""}',
          "*"
        );
      // !visible && (yt.current.src = `https://www.youtube-nocookie.com/embed/${image.url}?enablejsapi=1&version=3`);
    }, [visible])

    return (
        <div className="crtProductVideo">
            <iframe tabIndex={visible ? 0 : -1} width="560" height="315" src={`https://www.youtube-nocookie.com/embed/${image.url}?enablejsapi=1&version=3`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen ref={yt}></iframe>
        </div>
    )
};

const LargeImageSlide = ({
    slide,
    visible
}) => {

    switch (slide.family) {
        case 'ProductImage':
            return <SlideImage data={slide.data} />
            break;

        case 'ProductVideo':
            return <SlideVideo data={slide.data} visible={visible}/>
            break;

    }
}


export default LargeImageSlide
