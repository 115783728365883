import React from 'react'

const FullSlide = ({
    data: {
        url,
        priceValue,
        dateLabel,
        image,
        title,
        place,
        btnLabel,
        typeidentifier
    }
}) => {

    return (
        <a href={ url } className="crtProductPushFull">
            <picture>
                <source
                    media="(min-width: 1025px)"
                    data-srcset={`https://res.cloudinary.com/du5jifpgg/image/${ image.type }/t_push_full_w1440,q_auto,f_auto/${ image.url } 1x,
                        https://res.cloudinary.com/du5jifpgg/image/${ image.type }/t_push_full_w2880,q_auto,f_auto/${ imgUrl } 2x`} />
                <source
                    media="(min-width: 560px)"
                    data-srcset={`https://res.cloudinary.com/du5jifpgg/image/${ image.type }/t_push_full_w768,q_auto,f_auto/${ image.url } 1x,
                        https://res.cloudinary.com/du5jifpgg/image/${ image.type }/t_push_full_w1536,q_auto,f_auto/${ image.url } 2x`} />
                <source
                    media="(max-width: 560px)"
                    data-srcset={`https://res.cloudinary.com/du5jifpgg/image/${ image.type }/t_push_full_w360,q_auto,f_auto/${ image.url } 1x,
                        https://res.cloudinary.com/du5jifpgg/image/${ image.type }/t_push_full_w768,q_auto,f_auto/${ image.url } 2x`} />
                <img src={`https://res.cloudinary.com/du5jifpgg/image/${ image.type }/e_blur:1000,q_1,h_280,w_768,f_auto/${ image.url }`}
                    data-src={`https://res.cloudinary.com/du5jifpgg/image/${ image.type }/t_push_full_w768,q_auto,f_auto/${ image.url }`}
                    alt={ image.legend } className="crtProductPushFull-img lazyload tns-lazy-img" />
            </picture>
            <div className="crtProductPushFull-infos">
                {(
                  typeidentifier === "PrpFormula"
                  || typeidentifier === "FranceBilletEventOverride"
                  || typeidentifier === "FETE_ET_MANIFESTATION"
                  ) && place &&
                      <div className="crtProductPushFull-place">{ place }</div>
                }
                <div className="crtProductPushFull-title">{ title }</div>
                {(
                  typeidentifier === "FranceBilletEventOverride"
                  || typeidentifier === "FETE_ET_MANIFESTATION"
                  ) &&
                      <div className="crtProductPushFull-date">{ dateLabel }</div>
                }
                {(
                  typeidentifier === "FranceBilletEventOverride"
                  || typeidentifier === "PrpFormula"
                  ) && priceValue &&
                      <div className="crtProductPushFull-date">{ priceValue }</div>
                }
                {(
                  typeidentifier === "FranceBilletEventOverride"
                  || typeidentifier === "FETE_ET_MANIFESTATION"
                  ) && btnLabel &&
                    <div className="crtProductPushFull-actions">
                        <button className="crtBigBtn is-large">{ btnLabel }</button>
                    </div>
                }
            </div>
        </a>
    );
}

export default FullSlide
