import React, {useRef, useEffect, useLayoutEffect, useState, useMemo} from 'react'
import Hammer from 'hammerjs'
import debounce from 'lodash/debounce'

const CarouselList = ({
    children,
    dispatch,
    state: {
        currentView,
        nbSlides,
        gutter,
        slidesPerView,
        nbViews,
        swipe,
        classes,
        transitionDuration
}}) => {

    const containerRef = useRef(null);
    const listRef = useRef(null);
    const [listWidth, setListWidth] = useState(1);
    const [viewWidth, setViewWidth] = useState(0);
    const [force, setForce] = useState(false); // 💪
    const animation = useMemo(() => transitionDuration/1000, [transitionDuration]);

    const handleTranslate = (translateValue) => {
        if (!listRef.current) {
            return;
        }

        listRef.current.style.transform = `translateX(${translateValue}%)`;
        setTimeout(function(){
            listRef.current.classList.remove('inSwipe');
        }, transitionDuration);
    }

    const handleChangeView = (type) => {
        dispatch({type});
        setForce(force => !force);
    }

    useEffect(() => {
        function calculateSizes() {
            if (!containerRef.current) {
                return;
            }

            const refStyles = window.getComputedStyle(containerRef.current);
            const paddings = +refStyles.paddingLeft.replace(/\D/g, '') + +refStyles.paddingRight.replace(/\D/g, '');
            // The carousel container width without paddings in px
            const containerWidth = containerRef.current.offsetWidth - paddings;
            // The width of one slide in px
            const slideSize = (containerWidth-(slidesPerView-1)*gutter)/slidesPerView;
            // The list of the slides list caontainer in px
            const listWidth = slideSize*nbSlides + (nbSlides-1)*gutter;

            // Store the list width
            setListWidth(listWidth);
            // Store the "view" size
            setViewWidth(slideSize*slidesPerView + gutter*(slidesPerView-1));
            handleTranslate(-(viewWidth*currentView+(currentView)*gutter)*100/listWidth)
        }
        window.addEventListener('resize', debounce(calculateSizes, 150));
        //  First call
        calculateSizes();
        // remove listener on destroy
        return () => window.removeEventListener('resize', calculateSizes);
    }, [slidesPerView]);

    useLayoutEffect(() => {
        if (!listRef.current) {
            return;
        }

        listRef.current.style.transition = `transform ${animation}s`;
        handleTranslate(-(viewWidth*currentView+(currentView)*gutter)*100/listWidth);

        // Reset the transition CSS property
        function transitionListener () {
            listRef.current.style.transition = '';
            listRef.current.removeEventListener('transitionend', transitionListener, false);
        }

        listRef.current.addEventListener('transitionend', transitionListener, false);

        return () => {
            listRef.current.removeEventListener('transitionend', transitionListener, false);
        }
    }, [currentView, force])

    useLayoutEffect(() => {
        if (!containerRef.current) {
            return;
        }

        const hammertime = new Hammer(containerRef.current);

        const panstart = (e) => {
            listRef.current.classList.add('inSwipe');
        };

        const handleSwipe = (e) => {
            const sensitivity = 25
            // Calculate pixel movements into 1:1 screen percents so gestures track with motion
            var percentage = 100 / nbViews * e.deltaX / viewWidth;

            // Multiply percent by # of view we’re on
            var percentageCalculated = percentage - 100 / nbViews * currentView;

            handleTranslate(percentageCalculated)

            // Snap to slide when done
            if( e.isFinal ) {
                if( e.velocityX > 1 ) {
                    handleChangeView('PREV_VIEW')
                } else if( e.velocityX < -1 ) {
                    handleChangeView('NEXT_VIEW')
                } else {
                    if( percentage <= -( sensitivity / nbViews ) ) {
                        handleChangeView('NEXT_VIEW')
                    }
                    else if( percentage >= ( sensitivity / nbViews ) ) {
                        handleChangeView('PREV_VIEW')
                    }
                    else {
                        // reset translate at current view
                        handleTranslate(-(viewWidth*currentView+(currentView)*gutter)*100/listWidth)
                    }
                }
            }

        }
        hammertime.add( new Hammer.Pan({touchAction: 'auto', threshold: 0, pointers: 0 }) );
        hammertime.get('pan').set({ direction: Hammer.DIRECTION_HORIZONTAL });

        if (swipe) {
            hammertime.on('pan', handleSwipe)
            hammertime.on('panstart', panstart)
        }

        return () => {
            hammertime.off('pan', handleSwipe, 250);
            hammertime.off('panstart', panstart)
        }
    }, [slidesPerView, currentView, swipe, listWidth])

    const makeStyles = () => {
        return {
            display: 'grid',
            gridTemplateColumns: `repeat(${nbSlides}, 1fr)`,
            gridGap: gutter,
            transform: 'translateX(0)',
            width: listWidth,
            '--nbSlides': nbSlides,
            '--listWidth': listWidth,
            '--gutterSize': gutter
        }
    }


    return (
        <div className={classes.listContainer} ref={containerRef}>
            <ul className={classes.list} style={makeStyles()} ref={listRef}>{children}</ul>
        </div>
    )
}

export default CarouselList
